<template>
  <div class="wrapper">
    <!-- 页面头部部分 -->
    <div class="sljr-header" :class="{ 'in-index': routeName == 'Index' }">
      <!-- 水平一级菜单 -->
      <div class="header-center">
        <img src="@/assets/images/logo_01.png" class="header-logo" alt="logo" />

        <!--  menu-trigger="click"  :default-active="toIndex()"-->
        <el-menu
          mode="horizontal"
          @select="handleSelect"
          :default-active="defaultActive"
        >
          <template v-for="(item, idx) in items">
            <el-menu-item v-if="!item.subMenu" :index="item.index" :key="idx">
              {{ item.title }}
            </el-menu-item>
            <el-submenu
              v-if="item.subMenu"
              :index="item.index"
              :key="item.index"
            >
              <template slot="title">
                <router-link :to="'/' + item.index">
                  {{ item.title }}
                </router-link>
              </template>
              <!-- 子菜单 公益项目只显示前五个-->
              <template v-for="(subItem, i) in item.subMenu">
                <el-menu-item
                  :index="subItem.index"
                  :key="i"
                  :style="{
                    'margin-left': subItem.title.length > 7 ? '8px' : '',
                  }"
                  v-if="
                    item.index != 'pubilcWelfare' ||
                    (item.index == 'pubilcWelfare' && i < 5)
                  "
                >
                  {{ subItem.title }}
                </el-menu-item>
              </template>
            </el-submenu>
          </template>
        </el-menu>
      </div>
    </div>

    <!-- 页面左侧二级菜单栏，和主体内容区域部分 -->
    <el-main class="sljr-main" :class="{ 'in-index': routeName == 'Index' }">
      <!-- 头图 -->
      <div class="head-picture" v-if="routeName !== 'Index'">
        <img class="pic" :src="picUrl" alt="" />
        <div class="title">{{ pageTitle }}</div>
      </div>
      <!-- <div> -->
      <router-view></router-view>
      <!-- </div> -->
    </el-main>

    <!-- 页面底部 -->
    <el-footer class="sljr-footer" height="inherit">
      <div class="footer-content">
        <div class="menu">
          <!-- <img
            class="logo"
            src="../../assets/images/logo_footer.png"
            alt="logo"
          /> -->
          <template v-for="(item, index) in items">
            <div class="item" v-if="index > 0" :key="index">
              <div class="title">{{ item.title }}</div>
              <ul>
                <li
                  v-for="(sub, i) in item.subMenu"
                  :key="i"
                  @click="handleSelect(sub.index)"
                >
                  {{ sub.title }}
                </li>
              </ul>
            </div>
          </template>

          <div class="item">
            <div class="title">关注我们</div>
            <div class="follow">
              <img class="wx" :src="concactUs.contentUrl" alt="二维码" />
              <h4>扫码关注微信</h4>
              <p>地址：{{ concactUs.contentKeyword }}</p>
              <p>电话：{{ concactUs.contentAuthor }}</p>
              <p>邮箱：{{ concactUs.contentSource }}</p>
            </div>
          </div>
        </div>
        <div class="links">
          友情链接：<span v-for="i in linkList" :key="i.id">
            <a :href="i.typelink" target="_blank">{{ i.categoryTitle }}</a>
          </span>
        </div>
        <div class="links two">
          <a :href="beian.url" target="_blank">{{ beian.name }}</a>
        </div>
      </div>
    </el-footer>
  </div>
</template>
<script>
export default {
  computed: {
    // publicList () {
    //   return this.$store.state.publicWelfareList  // 取出公益项目
    // },
    concactUs () {
      return this.$store.state.concactUs
    }
  },
  data () {
    return {
      routeName: 'Index',
      pageTitle: '',
      defaultActive: '',
      categoryId: '',//当前一级菜单的栏目id 
      beian: {
        url: 'https://beian.miit.gov.cn/#/Integrated/index',
        name: '粤ICP备16119726号'
      },
      items: [    // 水平一级菜单栏的菜单
        {
          index: 'index',
          title: '首页'
        },
        {
          index: 'aboutUs',
          title: '关于我们',
          subMenu: [{
            title: '基金会介绍',
            index: 'aboutUs/intro'
          }, {
            title: '组织结构',
            index: 'aboutUs/framework'
          }, {
            title: '理事会',
            index: 'aboutUs/council'
          }, {
            title: '基金会章程',
            index: 'aboutUs/rule'
          }, {
            title: '机构荣誉',
            index: 'aboutUs/institutionalHonor'
          }, {
            title: '联系我们',
            index: 'aboutUs/contactUs'
          }]
        },
        {
          index: 'news',
          title: '新闻动态',
          subMenu: [{
            title: '机构动态',
            index: 'news/mechanism/mecList'
          }, {
            title: '项目动态',
            index: 'news/project/ProList'
          }, {
            title: '活动图集',
            index: 'news/album/albumList'
          }]
        },
        {
          index: 'pubilcWelfare',
          title: '公益项目',
          subMenu: []
        },
        {
          index: 'openInfomation',
          title: '信息公开',
          subMenu: [{
            index: 'openInfomation/annualReport',
            title: '年检年报'
          }, {
            index: 'openInfomation/auditReport',
            title: '审计报告'
          }, {
            title: '计划总结',
            index: 'openInfomation/planSummary'
          }, {
            title: '机构制度',
            index: 'openInfomation/mechanismSystem'
          }, {
            title: '捐款信息公示',
            index: 'openInfomation/donationInfo'
          }, {
            title: '其它信息',
            index: 'openInfomation/otherInfo/list'
          }]
        },
      ],
      linkList: [],
      picUrl: ''//头图
    }
  },
  created () {
    console.log('layout----', this.$route.path.substr(1))
    this.routeName = this.$route.name;
    this.pageTitle = this.$route.matched[1].meta.title;
    //一级导航高亮
    this.defaultActive = this.$route.path.substr(1)
    switch (this.$route.meta.parentName) {
      case 'AboutUs'://关于我们
        this.categoryId = '1376790144811700226'
        break;
      case 'News'://新闻动态
        this.categoryId = '1376791966871883777'
        break;
      case 'PublicWelfare'://公益项目
        this.categoryId = '1376794802837950466'
        break;
      case 'OpenInfomation'://信息公开
        this.categoryId = '1376793188848799746'
        break;
    }
    //头图
    if (this.routeName != 'Index') {
      this.getHeadPicture()
    }
    //公益项目
    this.getPublicWelfare()
    //友情链接
    this.getFriendLinks();
  },
  methods: {
    /**
     * @description: 获取头图
     * @param: 新闻动态：1376791966871883777  
     * 信息公开：1376793188848799746  
     * 关于我们：1376790144811700226  
     * 公益项目:1376794802837950466
     */
    getHeadPicture () {
      let params = {
        id: this.categoryId
      }
      this.$api.getHeadPicture(params).then((res) => {
        // console.log('获取头图', res.data)
        this.picUrl = res.data.data.categoryImg
      })
    },
    /**
   * @description: 获取公益项目
   * @param  {pageSize,categoryId}
   * @return {*}
   */
    getPublicWelfare () {
      let params = {
        pageSize: '999',
        categoryId: '1376794802837950466'//固定值
      }
      this.$api.getPublicWelfare(params).then(res => {
        console.log('公益项目', res)
        this.publicList = res.data.data.rows
        //存储公益项目列表
        this.$store.commit('updatePublicWelfareList', res.data.data.rows)
        //公益项目二级菜单
        this.publicList.forEach(item => {
          this.items[3].subMenu.push({
            title: item.categoryTitle,
            index: `pubilcWelfare/publictItem?id=${item.id}`
          })
        });
      })
    },
    // 根据路径绑定到对应的一级菜单，防止页面刷新重新跳回第一个
    toIndex () {
      return this.$route.path.split('/')[1];
    },
    // 切换菜单栏
    handleSelect (key) {
      //   console.log(key);
      this.$router.push('/' + key);
      //   this.defaultActive = key
    },
    /**
     * @description: 友情链接
     */
    getFriendLinks () {
      this.$api.getFriendLinks().then(res => {
        console.log('友情链接', res)
        this.linkList = res.data.data.rows
      })
    }
  },
  watch: {
    $route (n, old) {
      console.log('layout-route-change', n)
      this.routeName = n.name;
      this.pageTitle = n.matched[1].meta.title;
      //一级导航高亮
      this.defaultActive = n.path.substr(1)
      console.log('this.defaultActive', this.defaultActive)
      //判断切换一级标签获取头图
      if (n.meta.parentName !== old.meta.parentName) {
        switch (n.meta.parentName) {
          case 'AboutUs'://关于我们
            this.categoryId = '1376790144811700226'
            break;
          case 'News'://新闻动态
            this.categoryId = '1376791966871883777'
            break;
          case 'PublicWelfare'://公益项目
            this.categoryId = '1376794802837950466'
            break;
          case 'OpenInfomation'://信息公开
            this.categoryId = '1376793188848799746'
            break;
        }
        this.getHeadPicture();
      }
    }
  }
}
</script>
<style lang="scss" >
body {
  margin: 0;
}
.wrapper {
  width: 100%;
  height: 100%;
}

/* --------------- 水平一级菜单栏的样式--------------------- */
.sljr-header {
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 90px;
  font-size: 18px;
  // background: #e50113;
  background: #ffffff;
  z-index: 2021;
  &.in-index {
    // background: rgba(0, 0, 0, 0.5);
	// background: rgba(255, 255, 255, 0.5);
    .el-menu.el-menu--horizontal {
      background: rgba(255, 0, 0, 0);
    }
    .el-menu--horizontal .el-menu-item:not(.is-disabled):focus {
      // background: rgba(0, 0, 0, 0);
    }
    .el-menu--horizontal .el-menu-item:not(.is-disabled):hover,
    .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
      // background: rgba(0, 0, 0, 0);
    }
  }
}
.header-center {
  height: 100%;
  width: 1200px;
  margin: 0 auto;
}
.header-logo {
  /* width: 256px;
  height: 64px;
  margin-top: 13px; */
  margin-left: -92px;
      width: 441px;
      height: 96.1px;
      margin-top: -6px;
}
.el-menu.el-menu--horizontal {
  border: none;
  display: inline-block;
  float: right;
  height: 90px;
  background: #ffffff;
  font-family: SourceHanSansCN, "Microsoft YaHei", Avenir, Helvetica, Arial,
    sans-serif;
}
.el-menu--horizontal > .el-menu-item,
.el-menu--horizontal > .el-submenu .el-submenu__title {
  border-bottom: none;
  color: #ff0000;
  height: 90px;
  line-height: 90px;
  font-size: 18px;
  padding: 0 30px;
  font-family: SourceHanSansCN, "Microsoft YaHei", Avenir, Helvetica, Arial,
    sans-serif;
  font-weight: 400;
}
.el-menu--horizontal > .el-submenu .el-submenu__title a {
  color: #ff0000;
  text-decoration: none;
  font-size: 18px;
  height: 90px;
  line-height: 90px;
  display: inline-block;
}
.el-menu--horizontal > .el-submenu .el-submenu__title a.router-link-active {
  font-weight: bold;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus {
  background: #ffffff;
  color: #ff0000;
  font-weight: bold;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background: #eeeeee;
  color: rgba(255, 0, 0, 0.9);
  font-weight: bold;
}
.el-menu--horizontal > .el-menu-item.is-active,
.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: none;
  color: #ff0000;
  font-weight: bold !important;
}
.el-menu--horizontal > .el-submenu:focus .el-submenu__title {
  color: rgba(255, 255, 255, 0.9);
}
.el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
  display: none;
}

.el-menu--popup-bottom-start {
  margin-top: 0;
}
.el-menu--horizontal .el-menu .el-menu-item {
  height: 32px;
  line-height: 32px;
  color: #999;
  font-size: 16px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN, "Microsoft YaHei",
    Avenir, Helvetica, Arial, sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.el-menu--popup {
  padding: 0;
  border-radius: 0;
  width: 132px;
  min-width: initial;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 6px solid $main-color;
  .el-menu-item:not(.is-disabled):hover {
    background: #fff;
    color: $main-color !important;
	// color: #000000 !important;
  }
  .el-menu-item.is-active {
    color: #999 !important;
  }
}

// --------------------主内容----------------------
.sljr-main {
  padding: 90px 0 0 0;

  &.in-index {
    padding: 0;
  }
  .head-picture {
    position: relative;
    .pic {
      width: 100%;
      display: block;
      max-height: 340px;
    }
    .title {
      width: 1200px;
      margin: 0 auto;
      position: absolute;
      top: 50%;
      left: calc((100vw - 1200px) / 2);
      transform: translateY(-50%);
      font-weight: 500;
      color: #ffffff;
      font-size: 50px;
    }
  }
}
.sljr-footer {
  background: #222832;
  color: #fff;
  border-top: 6px solid #e50113;
  margin-top: 100px;
  .footer-content {
    width: $content-width;
    margin: 0 auto;
    padding-top: 45px;
    .menu {
      display: flex;
      position: relative;
      border-bottom: 1px solid rgba(255, 255, 255, 0.6);
      .logo {
        width: 256px;
        height: 64px;
        position: absolute;
        left: 0;
        bottom: 30px;
      }
      .item {
        flex: 1;
        margin-right: 40px;
        &:last-child {
          flex: 2;
          margin-right: 0;
        }
        .title {
          font-weight: bold;
          line-height: 46px;
          font-size: 18px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.6);
          padding-left: 14px;
          position: relative;
          &::before {
            content: "";
            display: inline-block;
            width: 4px;
            height: 18px;
            background: #e50113;
            margin-right: 10px;
            position: absolute;
            left: 0;
            top: 13px;
          }
        }
        ul {
          padding: 10px 0;
          li {
            color: rgba(255, 255, 255, 0.6);
            line-height: 30px;
            font-size: 16px;
            cursor: default;
            a {
              color: rgba(255, 255, 255, 0.6);
              &:hover {
                color: $main-color;
                text-decoration: none;
              }
            }
            &:hover {
              color: $main-color;
            }
          }
        }
        .follow {
          padding-top: 20px;
          padding-bottom: 30px;
          color: rgba(255, 255, 255, 0.6);
          position: relative;
          .beian {
            position: absolute;
            top: 16px;
            right: 0;
            color: rgba(255, 255, 255, 0.6);
            font-size: 16px;
            &:hover {
              color: $main-color;
            }
          }
          .wx {
            width: 110px;
            height: 110px;
            display: block;
          }
          h4 {
            font-size: 18px;
            font-weight: bold;
            //   margin-right: 10px 0;
            line-height: 38px;
          }
          p {
            font-size: 16px;
            line-height: 30px;
            text-indent: -48px;
            margin-left: 48px;
          }
        }
      }
    }
    .links {
      text-align: center;
      color: rgba(255, 255, 255, 0.6);
      font-size: 14px;
      margin-top: 30px;
      &.two {
        margin-top: 15px;
        padding-bottom: 30px;
      }
      a {
        color: rgba(255, 255, 255, 0.6);
        &:hover {
          color: $main-color;
        }
      }
      span {
        padding-left: 16px;
        cursor: default;
        &:hover {
          color: $main-color;
          text-decoration: underline;
        }
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
        &::after {
          content: "";
          width: 1px;
          height: 16px;
          display: inline-block;
          background: rgba(255, 255, 255, 0.6);
          margin-left: 16px;
          vertical-align: sub;
        }
      }
    }
  }
}
</style>